import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LOGIN = gql`
  mutation loginMutation($email: String!, $password: String!){
    authenticateUser(input: {email: $email, password: $password}){
      jsonWebToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetMutation($email: String!, $token: UUID!, $password: String!){
    changePassword(input: {email: $email, token: $token, password: $password}){
      boolean
    }
  }
`;
