import Peer from 'peerjs';
import io from 'socket.io-client';
import { useEffect } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { uploadFileToS3 } from '../../../api';
import { addStream, removeStream } from '../utils';

export default function useRapper() {
  const history = useHistory();
  const match = useRouteMatch('/:room');

  useEffect(() => {
    (async function init() {
      // Fetch our stream
      const myStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      // Record the stream
      const recorder = new MediaRecorder(myStream);
      this.setRecorder(recorder);

      const chunks = [];
      recorder.onstop = () => {
        uploadFileToS3(new File(chunks, 'best.webm'));
      };

      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      // Our stream will always be the first index
      this.setStreams([myStream]);

      // Create our socket to facilitate room with peers
      const socket = io(process.env.SOCKET_HOST);
      this.setSocket(socket);

      // Create our peer to send video back and forth
      const peer = new Peer(undefined, {
        path: process.env.PEER_PATH,
        host: process.env.PEER_HOST,
        port: process.env.PEER_PORT,
      });

      this.setPeer(peer);

      // Disconnect before we close the page
      window.onbeforeunload = () => {
        socket.close();
        peer.destroy();
      };

      peer.on('open', (peerId) => {
        if (!match || !match.params.room) return;

        socket.emit('join', {
          roomId: match.params.room,
          peerId,
          streamId: myStream.id,
        });
      });
      // If we receive a call from another user, answer it with our stream.
      // Upon connection, we receive their stream and add it to our list
      peer.on('call', (call) => {
        call.answer(myStream);
        call.on('stream', addStream.bind(this));
      });

      // Once we've been matched with other rappers of similiar preferences, we set
      // the cipher as ready to begin
      socket.on('ready', ({ roomId }) => {
        this.setReady(true);
        this.setSearching(false);
        history.push(`/${roomId}`);
        socket.emit('join', { roomId, peerId: peer.id, streamId: myStream.id });
      });

      // When a new user connects, we call them with our stream. They will pickup using
      // the above event handler `addStream`
      socket.on('user-connected', ({ peerId }) => {
        const call = peer.call(peerId, myStream);
        call.on('stream', addStream.bind(this));

        socket.emit('sync', {
          turn: this.turn.current,
          timer: this.timer.current,
          ready: this.ready.current,
          started: this.started.current,
          numRappers: this.numRappers.current,
          timePerRapper: this.timePerRapper.current,
        });
      });

      // Sync audio for newcomers and consistency
      socket.on('sync', async ({
        timer, trackTime, turn, timePerRapper, started, numRappers, ready,
      }) => {
        if (typeof ready === 'boolean') { this.setReady(ready); }
        if (typeof started === 'boolean') {
          if (started) { this.audioRef.current && this.audioRef.current.play(); }
          this.setStarted(started);
        }

        if (Number.isInteger(turn)) { this.setTurn(turn); }
        if (Number.isInteger(timer)) { this.setTimer(timer); }
        if (Number.isInteger(numRappers)) { this.setNumRappers(numRappers); }
        if (Number.isInteger(timePerRapper)) { this.setTimePerRapper(timePerRapper); }
        if (Number.isFinite(trackTime)) { this.audioRef.current.currentTime = trackTime; }
      });

      // When a user disconnects, we take their stream out of our list
      socket.on('user-disconnected', ({ streamId }) => {
        removeStream.call(this, streamId);
        socket.emit('sync', {
          turn: 0,
          timer: this.timer.current,
          ready: this.ready.current,
          started: this.started.current,
          numRappers: this.numRappers.current,
          timePerRapper: this.timePerRapper.current,
        });
      });
    }.call(this));
  }, []);
}
