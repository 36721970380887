import { useRef, useState } from 'react';

export default function useLiveState(value) {
  const liveState = useRef(value);
  const setState = useState()[1];

  return [liveState, (newValue) => {
    liveState.current = newValue;
    setState(newValue);
  }];
}
