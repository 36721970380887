import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_ARTIST_BY_USER_ID = gql`
  query fetchArtist($user: UUID!){
    allArtistsList(condition: { userId: $user}) {
      username
    }
  }
`;
