export const SCREEN_WIDTHS = {
  1: '100%',
  2: '35%',
  3: '25%',
  4: '18%',
  5: '18%',
};

export function convertPercentStringToNumber(percentString) {
  return +percentString.slice(0, -1);
}

export function convertNumberToPercentString(number) {
  return `${number}%`;
}

export function sortStreams(streams) {
  return streams.slice().sort((a, b) => a.id.localeCompare(b.id));
}

export function isMyTurn(streams, turn) {
  if (turn === null || !streams || !streams.length || turn >= streams.length) return false;

  return sortStreams(streams)[turn].id === streams[0].id;
}

export function getScreenWidth(streams, timer) {
  if (timer && streams.length === 1) {
    return null;
  }

  return SCREEN_WIDTHS[streams.length];
}

// Function to add new stream
export function addStream(stream) {
  this.setStreams((otherStreams) => {
    const streamExists = otherStreams.some((otherStream) => otherStream.id === stream.id);

    // Only add new stream if we don't already have it
    return streamExists ? otherStreams : [...otherStreams, stream];
  });
}

// Function to remove stream
export function removeStream(streamId) {
  this.setStreams((otherStreams) => (
    otherStreams.filter((otherStream) => otherStream.id !== streamId)
  ));
}
