import React from 'react';
import clsx from 'clsx';

import {
  makeStyles, Link, AppBar, Toolbar,
} from '@material-ui/core';

import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar elevation={0} style={{ color: theme.palette.common.white }} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Link
          variant="h6"
          underline="none"
          color="inherit"
          className={classes.title}
          href="/premium-themes/onepirate/"
        >
          Cyphr
        </Link>
        <div className={classes.right}>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.rightLink}
            href="/premium-themes/onepirate/sign-in/"
          >
            Sign In
          </Link>
          <Link
            variant="h6"
            underline="none"
            className={clsx(classes.rightLink, classes.linkSecondary)}
            href="/premium-themes/onepirate/sign-up/"
          >
            Sign Up
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
}
