/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import JWTDecode from 'jwt-decode';
import { Formik, Form } from 'formik';
import { useCookies } from 'react-cookie';
import { useApolloClient, useMutation } from '@apollo/client';

import {
  makeStyles, Avatar, CssBaseline, Link, Paper, Box, Grid, Typography,
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { useHistory } from 'react-router-dom';
import { Copyright } from '../molecules';
import { TextField, ButtonField } from '../fields';

import { LOGIN } from '../../api/mutations';
import { FETCH_ARTIST_BY_USER_ID } from '../../api/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://images.unsplash.com/photo-1505964135045-cdada2f6bf43?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const client = useApolloClient();

  const history = useHistory();
  const [login] = useMutation(LOGIN);
  const [_, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* Left side of screen */}
      <Grid item xs={false} sm={4} md={7} className={classes.image} />

      {/* Right side of screen */}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik initialValues={{ email: '', password: '' }}>
            {({ values: { email, password } }) => (
              <Form className={classes.form}>
                <TextField label="Email Address" name="email" />
                <TextField label="Password" name="password" />
                <ButtonField
                  fullWidth
                  loading={loading}
                  className={classes.submit}
                  onClick={async (event) => {
                    event.preventDefault();

                    setLoading(true);

                    const loginResponse = await login({ variables: { email, password } });
                    const { jsonWebToken: encodedToken } = loginResponse.data.authenticateUser;

                    const token = JWTDecode(encodedToken);

                    const { data: { allArtistsList } } = await client.query({
                      query: FETCH_ARTIST_BY_USER_ID,
                      variables: { user: token.user_id },
                    });

                    const artist = allArtistsList[0];

                    setCookie('user', {
                      id: token.user_id,
                      role: 'artist',
                      username: artist.username,
                      token: encodedToken,
                    });

                    history.push('/dashboard');
                    setLoading(false);
                  }}
                >
                  Sign In
                </ButtonField>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
