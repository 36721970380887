import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BEAT = gql`
  mutation createBeat($artist: UUID!, $title: String!, $genre: BeatGenre!) {
    createBeat(input:{
      beat: {
        title: $title,
        artistId: $artist,
        genre: $genre
      }
    }) {
      beat {
        id
      }
    }
  }
`;
