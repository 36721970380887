import { useEffect } from 'react';

export default function useCountdown() {
  useEffect(() => {
    if (!this.started.current) {
      return () => { };
    }

    if (this.timer.current > 0) {
      const tick = setTimeout(() => this.setTimer(this.timer.current - 1), 1000);

      return () => { clearTimeout(tick); };
    }

    this.setTurn(0);
    return () => { };
  }, [this.timer.current, this.started.current]);
}
