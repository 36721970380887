/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable object-property-newline */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Grid } from '@material-ui/core';

import Stream from './Stream';
import Options from './Options';

import useRapper from './hooks/rapper';
import useMicPass from './hooks/pass';
import useLiveState from './hooks/state';
import useCountdown from './hooks/countdown';

import { FETCH_BEATS } from '../../api/queries';
import { getScreenWidth, isMyTurn, sortStreams } from './utils';
import Progress from './Progress';

export default function Cypher() {
  // Define a placeholder for an audio tag reference
  const audioRef = useRef(null);
  const [elapsed, setElaspsed] = useState(0);

  // Declare state for our component
  const [peer, setPeer] = useState(null);
  const [socket, setSocket] = useState(null);
  const [streams, setStreams] = useState([]);
  const [recorder, setRecorder] = useState(null);
  const [searching, setSearching] = useState(false);

  const [turn, setTurn] = useLiveState(null);
  const [timer, setTimer] = useLiveState(1);
  const [ready, setReady] = useLiveState(false);
  const [started, setStarted] = useLiveState(false);
  const [numRappers, setNumRappers] = useLiveState(5);
  const [timePerRapper, setTimePerRapper] = useLiveState(15);

  // Grab beat metadata
  const { loading, data } = useQuery(FETCH_BEATS);

  // waits for the cipher to start, then counts down to begin!
  useCountdown.call({ setTurn, setTimer, timer, started });

  // If its your turn, start rapping. Otherwise shhhhh
  useMicPass.call({ socket, streams, audioRef, turn, timePerRapper });

  // Initialize the rapper (video, event handlers, p2p communication, etc.)
  useRapper.call({
    // Setters
    setStreams, setSocket, setPeer, setTurn, setReady, setTimer,
    setStarted, setSearching, setTimePerRapper, setNumRappers, setRecorder,

    // Refs
    turn, timer, ready, started, audioRef, numRappers, timePerRapper,
  });

  // Only render the streams if all of our dependencies are ready
  if (!streams.length || !socket || !peer || loading) {
    return null;
  }

  // Grab the beat from s3
  const beatMeta = data.allBeatsList[0];
  const beatURL = `${process.env.AWS_S3_HOST}/${beatMeta.id}-${beatMeta.title}`;

  // Render the component
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#333',
          justifyContent: !timer.current && 'space-evenly',
        }}
        container
        direction="row"
      >
        {/* Render all the streams  */}
        {sortStreams(streams).map((stream, index) => (
          <Stream
            width={getScreenWidth(streams, timer.current)}
            height={!timer.current && 'calc(100vh - 50px)'}
            stream={stream}
            noEcho={stream.id === streams[0].id}
            turn={index === turn.current}
            key={stream.id}
          />
        ))}

        {/* Render the cypher options */}
        {Boolean(timer.current) && (
          <Options
            socket={socket}
            searching={searching}
            setSearching={setSearching}
            timer={timer.current}
            ready={ready.current}
            started={started.current}
            numRappers={numRappers.current}
            timePerRapper={timePerRapper.current}
          />
        )}
      </Grid>

      {!timer.current && <Progress elapsed={elapsed} end={audioRef.current && audioRef.current.duration} />}

      <audio
        controls
        muted={!isMyTurn(streams, turn.current)}
        onTimeUpdate={() => { setElaspsed(audioRef.current.currentTime); }}
        ref={audioRef}
        src={beatURL}
      />
    </>
  );
}
