import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Cypher from './cypher';
import { Header } from './layout';
import { Login, Reset } from './auth';
import theme from '../theme';

export default function App() {
  return (
    <Switch>
      <Route exact path={['/reset']} component={Reset} />
      <Route exact path={['/login', '/start']} component={Login} />
      <Route
        exact
        path={['/', '/:roomId']}
        component={() => (
          <>
            <Header />
            <Cypher />

            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: theme.palette.common.black,
                opacity: 0.5,
                zIndex: -1,
              }}
            />
          </>
        )}
      />
      {/* <Route exact path={['/:artist']} component={Dashboard} /> */}
    </Switch>
  );
}
