import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import { ButtonField, SelectField } from '../fields';

export default function Options({
  ready, numRappers, timePerRapper, socket, searching, setSearching, started, timer,
}) {
  // Grabs uuid off url
  const match = useRouteMatch('/:room');
  const roomId = match && match.params.room;

  return (
    <Formik
      enableReinitialize
      initialValues={{ numRappers, timePerRapper }}
    >
      {({ values }) => (
        <>
          <Grid container style={{ margin: 'auto', width: '20%' }} direction="column">
            {/* Render the timer if we've started */}

            <Typography style={{ padding: '0.5em', color: 'white' }} color="inherit" align="center" variant="h1" marked="center">
              {(started && timer) ? timer : "Let's Rap"}
            </Typography>

            {!ready && !roomId && !started && (
              <>
                <SelectField
                  name="numRappers"
                  label="# of Rappers"
                  options={[1, 2, 3, 4, 5]}
                  onChange={(value) => { socket.emit('sync', { numRappers: value }); }}
                />
                <SelectField
                  name="timePerRapper"
                  label="Time per Rapper"
                  onChange={(value) => { socket.emit('sync', { timePerRapper: value }); }}
                  options={[15, 30, 45, 60]}
                />
              </>
            )}

            {!started && (
              <ButtonField
                loading={searching}
                onClick={(event) => {
                  event.preventDefault();

                  if (!searching && !roomId) {
                    socket.emit('searching', values);
                    setSearching(true);
                    return;
                  }

                  socket.emit('sync', { started: true });
                }}
              >
                Go
              </ButtonField>
            )}
          </Grid>
        </>
      )}
    </Formik>
  );
}
