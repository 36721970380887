import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import {
  makeStyles, Avatar, Typography, Container, Grid, Link, Box,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Copyright } from '../molecules';
import { ButtonField, TextField } from '../fields';

import { RESET_PASSWORD } from '../../api/mutations';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const query = new URLSearchParams(useLocation().search);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Formik initialValues={{
          email: query.get('email'),
          token: query.get('token'),
          password: '',
        }}
        >
          {({ values: { email, token, password } }) => (
            <Form className={classes.form}>
              <TextField label="New Password" name="password" type="password" />
              <ButtonField
                fullWidth
                loading={loading}
                className={classes.submit}
                onClick={async (event) => {
                  event.preventDefault();

                  setLoading(true);

                  const {
                    data: { changePassword: { boolean: wasSuccessful } },
                  } = await resetPassword({
                    variables: { token, email, password },
                  });

                  if (wasSuccessful) {
                    history.push('/login');
                  }

                  setLoading(false);
                }}
              >
                Reset Password
              </ButtonField>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Back to the Cypher
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
