import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { ClipLoader } from 'react-spinners';

export default function ButtonField({
  onClick,
  children,
  className,

  type = 'submit',
  color = 'primary',
  loading = false,
  fullWidth = false,
  variant = 'contained',
}) {
  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      onClick={onClick}
      fullWidth={fullWidth}
      className={className}
    >
      {!loading ? children : <ClipLoader color="white" loading={loading} size={20} />}
    </Button>
  );
}
