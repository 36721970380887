import React from 'react';
import { TextField as Text } from '@material-ui/core';
import { useField } from 'formik';

export default function TextField({ label, name, type }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Text
      variant="outlined"
      margin="normal"
      required
      fullWidth
      type={type}
      id={name}
      label={label}
      name={name}
      autoFocus
      value={field.value}
      onChange={(event) => {
        helpers.setValue(event.target.value);
      }}
    />
  );
}
