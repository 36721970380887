import { useEffect } from 'react';

import { isMyTurn } from '../utils';

export default function useMicPass() {
  useEffect(() => {
    // if socket is not ready or the cypher has yet to begin, kill this effect
    if (!this.socket || !this.audioRef.current || this.turn.current === null) {
      return;
    }

    // If its not your turn to rap, quiet yourself
    if (!isMyTurn(this.streams, this.turn.current)) {
      this.streams[0].getAudioTracks()[0].enabled = false;
      return;
    }

    // Otherwise, you up baby. Unmute yourself
    this.streams[0].getAudioTracks()[0].enabled = true;

    // Once you're done rapping, pass it to the next head
    const rapper = setTimeout(async () => {
      const nextTurn = (this.turn.current + 1) % this.streams.length;
      this.socket.emit('sync', {
        turn: nextTurn,
        trackTime: this.audioRef.current.currentTime,
        timePerRapper: this.timePerRapper.current,
      });
    }, this.timePerRapper.current * 1000);

    // eslint-disable-next-line consistent-return
    return () => { clearTimeout(rapper); };
  }, [this.turn.current, this.socket]);
}
