import { Grid, LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: '50px',
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Progress({ elapsed, end }) {
  const classes = useStyles();

  return (
    <Grid className={classes.footer}>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={(elapsed / end) * 100}
      />
    </Grid>
  );
}
