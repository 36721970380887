// eslint-disable-next-line import/prefer-default-export
export async function uploadFileToS3(file) {
  const preSignedResponse = await fetch(process.env.AWS_PRESIGN_HOST, {
    method: 'POST',
    body: JSON.stringify({
      fileName: file.name,
      fileType: file.type,
    }),
  });

  await fetch(await preSignedResponse.text(), {
    method: 'PUT',
    mode: 'cors',
    body: file,
  });
}
