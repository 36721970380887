import React, { useCallback } from 'react';
import { Box } from '@material-ui/core/';
import crown from '../../media/crown.png';

export default function Stream({
  stream, noEcho, width, turn, height,
}) {
  const videoRef = useCallback((video) => {
    if (video !== null) {
      video.srcObject = stream;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }
  }, []);

  return (
    <video
      style={{
        height: height || '100vh',
        width,
      }}
      muted={noEcho}
      ref={videoRef}
    />
  );
}
