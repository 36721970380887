import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_BEATS = gql`{
  allBeatsList {
    id
    title
    genre
    artistByArtistId {
      username
    }
  }
}`;
