import React from 'react';

import {
  FormControl, InputLabel, makeStyles, MenuItem, Select,
} from '@material-ui/core';
import { useField } from 'formik';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectField({
  name, label, options, onChange = (_) => _,
}) {
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        style={{ backgroundColor: 'silver' }}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={field.value || ''}
        onChange={(event) => {
          helpers.setValue(event.target.value);
          onChange(event.target.value);
        }}
        label={label}
      >
        {options.map((option) => {
          const optionLabel = option.label !== undefined ? option.label : option;
          const optionValue = option.value !== undefined ? option.value : option;

          return (
            <MenuItem key={optionLabel} value={optionValue}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
