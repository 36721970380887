import React from 'react';
import { Link, Typography } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Cyphr
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
